<template>
    <div class="education-contain">
        <el-scrollbar class="contain-box">
            <div class="top-box">
                <left-tab/>
                <div class="top-title">
                    <div class="title-box">
                        <div class="title-text">{{title}}</div>
                        <div class="title-info">{{description}}</div>
                    </div>
                </div>
            </div>
            <div class="main-box">
                <div class="main-box-contain box1">
                    <div class="top-card">
                        <div class="top-card-contain">
                            <div class="left-card-box">
                                <div class="card-title">课程体系概述</div>
                                <div class="divide-line">
                                    <div class="left-line"></div>
                                    <div class="right-line"></div>
                                </div>
                                <div class="card-text">
                                    一课课程体系是目前电商最全面、最系统的课程资源，以“岗位引导，项目驱动”为指导，通过岗位基础、岗位核心、综合实战、行业应用四大课程模块围绕着电商核心岗位循序渐进，不断深入。
                                </div>
                            </div>
                            <div class="right-card-box">
                                <img src="../../../assets/images/education/course_overview.png" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="bottom-card">
                        <div class="box-title">课程特色</div>
                        <div class="card-item-box">
                            <div class="card-item" v-for="item in cardList" :key="item.image">
                                <div class="card-img" :style="{backgroundImage: 'url(' + (item.background) + ')'}">
                                    <img :src="item.image" alt="">
                                </div>
                                <div class="card-item-title">{{item.title}}</div>
                                <div class="card-item-content">{{item.content}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="main-box-contain box2">
                    <div class="top-box-content">
                        <div class="box-title">课程资源</div>
                        <div class="title-icon-box">
                            <div :class="currentActive === item.id?'icon-item active':'icon-item'"
                                 v-for="item in resourceList" @click="changeShow(item)">
                                <div class="item-content">
                                    <img :src="item.image" alt="">
                                    <div class="item-title">{{item.name}}</div>
                                    <div class="item-bottom-line" :style="{background:item.color}"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="main-box">
                        <div class="left-card-box">
                            <div class="card-title">{{cardContent.name}}</div>
                            <div class="card-text">
                                {{cardContent.content}}
                            </div>
                        </div>
                        <div class="right-card-box">
                            <img :src="cardContent.show" alt="">
                        </div>
                    </div>
                </div>
                <div class="main-box-contain box3">
                    <div class="top-box-content">
                        <div class="box-title">O2O双向互动实战教学模式</div>
                        <img src="../../../assets/images/education/course-systemDetail.png">
                    </div>
                </div>
                <div class="main-box-contain box4">
                    <div class="top-box-content">
                        <div class="box-title">一课电子商务专业实践系列课程</div>
                        <div class="img-box">
                            <img src="../../../assets/images/education/course-table.png" alt="">
                        </div>
                    </div>
                    <OfficialFooter style="background: rgba(3,3,47,0.85)"/>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
    import leftTab from "@/components/education/leftTab";
    import OfficialFooter from '@/components/OfficialFooter';

    export default {
        name: "Education",
        components: {
            leftTab,
            OfficialFooter
        },
        data() {
            return {
                title: '一课电子商务课程体系',
                description: "一课电子商务课程采取“情境导入、任务驱动”的开发思路，基于企业真实的项目， 结合O2O双向互动实战教学模式，线上线下融合，打造的新一代智慧课程。",
                cardList: [
                    {
                        image: require('../../../assets/images/education/card1.png'),
                        title: '线上线下，融合贯穿',
                        content: '线上资源库平台，线下二维码教材，互通互联，互相配合，运用新技术与新教育模式打造一站式信息化教学平台，提升教学体验与效果',
                        background: require('../../../assets/images/education/card1-back.png')
                    },
                    {
                        image: require('../../../assets/images/education/card2.png'),
                        title: '互通互联，打造一站式教学体验',
                        content: '课程实训通过实训指导书与实训软件结合，环环相扣，难易可控“学中做，做中学”，提升了教学效果',
                        background: require('../../../assets/images/education/card2-back.png')
                    },
                    {
                        image: require('../../../assets/images/education/card3.png'),
                        title: '资源丰富，落地轻松',
                        content: '数十种资源类型，应有尽有，教材、课件、教案、微课、案例、实训指导书、素材、配套文章等，并进行关联，提供灵活而有趣的上课体验',
                        background: require('../../../assets/images/education/card3-back.png')
                    },
                    {
                        image: require('../../../assets/images/education/card4.png'),
                        title: '一键上课',
                        content: '只需一键导入，即可将所有课程资源与教学平台进行关联，备好的课程能够直接关联到班级，实训指导书与软件能够无缝对接，多好的体验',
                        background: require('../../../assets/images/education/card4-back.png')
                    },
                    {
                        image: require('../../../assets/images/education/card5.png'),
                        title: '课程全面，难易结合',
                        content: '共开发25门电商/跨境电商核心课程，课程难易结合、互相关联，体系全面',
                        background: require('../../../assets/images/education/card5-back.png')
                    },
                    {
                        image: require('../../../assets/images/education/card6.png'),
                        title: '实时更新',
                        content: '采取互联网迭代思维，每周定期更新，保证电商知识不过时',
                        background: require('../../../assets/images/education/card6-back.png')
                    },
                ],
                currentActive: 1,
                resourceList: [
                    {
                        id: 1,
                        name: '课件',
                        image: require('../../../assets/images/education/courseware.png'),
                        color: '#107EF5',
                        content: "与传统的课件不同，我们开发的智能课件系统具备高级pin图钉功能，能够将其它相关资源pin到PPT内实现混合式教学，有效控制课堂难度；AI虚拟助手的加入，将会对智能课件更好的诠释，课件更智能、教学请轻松。",
                        show: require('../../../assets/images/education/show-courseware.png'),

                    },
                    {
                        id: 2,
                        name: '教材',
                        image: require('../../../assets/images/education/textbook.png'),
                        color: '#05BF02',
                        content: '教材采取更适合职业院校的任务式教学设计，线上线下打通，通过混合式教学与其它资源进行对接串联；教材品质卓越，设计美观，内容新颖，且定期更新。',
                        show: require('../../../assets/images/education/show-textbook.png')
                    },
                    {
                        id: 3,
                        name: '微课',
                        image: require('../../../assets/images/education/micro-lesson.png'),
                        color: '#AB7DFA',
                        content: '每套课程尽量搭配更多、更好的微课，提升课堂的趣味性与品质；MG动画的制作成本是FLASH的10倍，我们的微课提供大量MG动画，每个任务开发一个MG动画与多个疑点难点的录屏微课，能够大大提升教学质量。',
                        show: require('../../../assets/images/education/show-micro.png')
                    },
                    {
                        id: 4,
                        name: '教学设计',
                        image: require('../../../assets/images/education/teach-design.png'),
                        color: '#F5C02D',
                        content: '教案是教师必备的教学工具，我们在线教案加入自主编辑、打印下载等功能，大大提升了教学便利；同时，我们提供多类教案模板，让教学更多样。',
                        show: require('../../../assets/images/education/show-teachdesign.png')
                    },
                    {
                        id: 5,
                        name: '测试中心',
                        image: require('../../../assets/images/education/testcenter.png'),
                        color: '#F27902',
                        content: '为学校提供一站式考试平台和技能评测系统，全方位地、多方面地评价学生的岗位技能水平、综合实践能力以及就业能力。帮助教师快速检测学生学习效果。平台可以根据预设答案实现自动评分，大大减轻老师的工作量，学生在提交试卷后可以快速查看成绩和错题，加深对知识点的理解与吸收。',
                        show: require('../../../assets/images/education/show-testcenter.png')
                    },
                    {
                        id: 6,
                        name: '实训中心',
                        image: require('../../../assets/images/education/training-center.png'),
                        color: '#A478F0',
                        content: '实训指导书是关联课程与实训软件最有效的渠道，每个任务尽量增加更多的实训任务，教师可以根据实际情况挑选合适的实训任务进行教学，与实训软件结合真正做到实训教学模式，提升学生技能水平。',
                        show: require('../../../assets/images/education/show-traincenter.png')
                    },
                    {
                        id: 7,
                        name: '配套材料',
                        image: require('../../../assets/images/education/person-resource.png'),
                        color: '#0F79EB',
                        content: '除此之外，我们提供大量配套资源，如素材包、相关文章、插件等，旨在提供更灵活、更全面、更丰富的教学体验，打造真正的智能上课模式。',
                        show: require('../../../assets/images/education/show-peitao.png')
                    },
                ],
                cardContent: {
                    name: '课件',
                    content: "与传统的课件不同，我们开发的智能课件系统具备高级pin图钉功能，能够将其它相关资源pin到PPT内实现混合式教学，有效控制课堂难度；AI虚拟助手的加入，将会对智能课件更好的诠释，课件更智能、教学请轻松。",
                    show: require('../../../assets/images/education/show-courseware.png')
                }
            }
        },
        methods: {
            changeShow(item) {
                this.currentActive = item.id;
                this.cardContent.name = item.name;
                this.cardContent.content = item.content;
                this.cardContent.show = item.show;
            },
        }
    }
</script>

<style scoped lang="scss">
    .education-contain {
        height: 100%;
        width: 100%;

        .contain-box {
            height: 100%;
            width: 100%;

            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }
        }

        .top-box {
            width: 100%;
            height: 466px;
            position: relative;
            background-image: url("../../../assets/images/education/course_system.png");
            background-repeat: no-repeat;
            background-size: cover;

            .top-title {
                width: 1150px;
                margin: 0 auto;
                padding-top: 110px;

                .title-box {
                    padding: 0 350px 0 110px;

                    .title-text {
                        font-size: 45px;
                        color: #FFFFFF;
                    }

                    .title-info {
                        width: 660px;
                        line-height: 30px;
                        font-size: 18px;
                        color: #FFFFFF;
                        margin-top: 40px;
                    }
                }
            }
        }

        .main-box {
            width: 100%;

            .main-box-contain {
                background-image: url("../../../assets/images/education/circle.png");
                background-repeat: repeat;
                background-size: cover;

                .box-title {
                    text-align: center;
                    font-size: 30px;
                    color: #333333;
                }

                &.box1 {
                    background-image: url("../../../assets/images/education/circle.png"), url("../../../assets/images/education/purple_back.png");
                    background-repeat: no-repeat no-repeat;
                    background-size: cover, contain;
                    background-position: top, bottom;
                    position: relative;

                    .top-card {
                        width: 1150px;
                        background: #FFFFFF;
                        box-shadow: 0px 2px 10px 0px rgba(8, 51, 149, 0.1);
                        border-radius: 10px;
                        height: 397px;
                        position: absolute;
                        top: -100px;
                        left: 50%;
                        transform: translate3d(-50%, 0, 0);

                        .top-card-contain {
                            display: flex;
                            padding: 30px 50px 30px 90px;
                            justify-content: space-between;

                            .left-card-box {

                                .card-title {
                                    font-size: 30px;
                                    color: #333333;
                                }

                                .divide-line {
                                    margin-top: 27px;
                                    display: flex;

                                    .left-line {
                                        width: 30px;
                                        height: 2px;
                                        background: #584EEE;
                                    }

                                    .right-line {
                                        width: 30px;
                                        height: 2px;
                                        background: #F36F21;
                                    }
                                }

                                .card-text {
                                    margin-top: 40px;
                                    width: 580px;
                                    font-size: 16px;
                                    color: #333333;
                                    line-height: 48px;
                                }

                            }
                        }
                    }

                    .bottom-card {
                        width: 1150px;
                        margin: 0 auto;
                        height: 700px;
                        padding-top: 450px;
                        padding-bottom: 50px;

                        .card-title {
                            text-align: center;
                            font-size: 28px;
                            color: #333333;
                        }

                        .card-item-box {
                            display: flex;
                            flex-wrap: wrap;

                            .card-item {
                                margin-top: 40px;
                                width: calc(33% - 10px);
                                height: 292px;
                                background: #FFFFFF;
                                box-shadow: 0px 2px 10px 0px rgba(8, 51, 149, 0.1);
                                border-radius: 10px;
                                margin-right: 20px;

                                &:hover {
                                    margin-top: 25px;
                                    transition: 0.5s;
                                }

                                &:nth-of-type(3n) {
                                    margin-right: 0;
                                }

                                &:first-child {
                                    .card-img {
                                        width: 260px;
                                        height: 125px;
                                    }
                                }

                                &:nth-of-type(2) {
                                    .card-img {
                                        height: 126px;
                                    }
                                }

                                &:nth-of-type(3) {
                                    .card-img {
                                        height: 116px;
                                    }
                                }

                                &:nth-of-type(5) {
                                    .card-img {
                                        width: 150px;
                                    }
                                }

                                &:nth-of-type(4) {
                                    .card-img {
                                        width: 150px;
                                    }
                                }

                                &:nth-of-type(6) {
                                    .card-img {
                                        height: 124px;
                                    }
                                }
                            }

                            .card-img {
                                text-align: center;
                                width: 170px;
                                height: 120px;
                                margin: 0 auto;

                                img {
                                    margin-top: 40px;
                                }
                            }

                            .card-item-title {
                                text-align: center;
                                font-size: 16px;
                                color: #222222;
                                margin-top: 10px;
                            }

                            .card-item-content {
                                width: 310px;
                                height: 39px;
                                font-size: 14px;
                                font-weight: 300;
                                color: #666666;
                                line-height: 24px;
                                margin: 40px auto;
                                text-align: center;
                            }
                        }
                    }
                }

                &.box2 {
                    background-color: #FFFFFF;

                    .top-box-content {
                        width: 1150px;
                        margin: 0 auto;
                        padding-top: 100px;

                        .title-icon-box {
                            width: 1150px;
                            margin-top: 40px;
                            display: flex;

                            .icon-item {
                                width: 108px;
                                height: 120px;
                                margin-right: 65px;
                                cursor: pointer;

                                &:nth-of-type(7) {
                                    margin-right: 0;
                                }

                                &.active {
                                    box-shadow: 0px 4px 8px 1px rgba(9, 95, 185, 0.17);
                                    border-radius: 6px;
                                    transition: 0.5s;
                                }

                                .item-content {
                                    padding: 20px;
                                    text-align: center;
                                    position: relative;

                                    .item-title {
                                        font-size: 16px;
                                        margin-top: 5px;
                                    }

                                    .item-bottom-line {
                                        width: 30px;
                                        height: 3px;
                                        position: absolute;
                                        bottom: 0;
                                        left: 38px;
                                    }
                                }
                            }
                        }
                    }

                    .main-box {
                        width: 1150px;
                        padding: 200px 0;
                        margin: 0 auto;
                        display: flex;

                        .left-card-box {
                            padding: 100px 100px 100px 50px;

                            .card-title {
                                font-size: 24px;
                                font-weight: 300;
                                color: #222222;
                                text-align: center;
                            }

                            .card-text {
                                margin-top: 50px;
                                width: 440px;
                                font-size: 14px;
                                color: #666666;
                                font-weight: 300;
                                line-height: 24px;
                                text-indent: 2em;
                            }
                        }

                        .right-card-box {
                            background-image: url("../../../assets/images/education/pc.png");
                            background-size: cover;
                            width: 553px;
                            height: 448px;

                            img {
                                padding-top: 17px;
                                padding-left: 16px;
                            }
                        }
                    }
                }

                &.box3 {
                    background-color: #F7FAFB;

                    .top-box-content {
                        width: 1150px;
                        margin: 0 auto;
                        padding-top: 100px;

                        img {
                            padding: 150px 100px;
                        }
                    }
                }

                &.box4 {
                    background-color: #FFFFFF;
                    background-image: url("../../../assets/images/education/circle.png"), url("../../../assets/images/about/lift-hand.jpg");
                    background-repeat: repeat no-repeat;
                    background-size: cover, contain;
                    background-position: top, bottom;

                    .top-box-content {
                        width: 1150px;
                        margin: 0 auto;
                        padding-top: 100px;
                        padding-bottom: 100px;

                        .table-box {
                            width: 1150px;
                            height: 805px;
                            background: #FFFFFF;
                            box-shadow: 0px 0px 32px 6px rgba(30, 26, 84, 0.1);
                            border-radius: 20px;
                            margin-top: 50px;

                            .img-box {
                                padding: 40px;
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }
    }
</style>